<template>
  <v-container class="mt-9">
    <v-subheader class="title mb-4 text-uppercase pa-0">
      <v-tooltip bottom color="yellow darken-3">
        <template v-slot:activator="{ on }">
          <v-icon class="mr-3" color="primary" v-on="on" @click="$router.back()"
            >mdi-arrow-left</v-icon
          >
        </template>
        <span>Retornar</span>
      </v-tooltip>
      <h3 class="title text-uppercase" style="color: #2054af">
        Trechos das viajens para comparação
      </h3>
    </v-subheader>
    <v-divider />
    <v-card flat>
      <v-card-title class="pa-2">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
          flat
          solo
          :disabled="items.length == 0 ? true : false"
        ></v-text-field>
        <v-divider vertical class="mr-2" />
        <v-btn
          color="primary"
          text
          dark
          class="mb-2 mt-2"
          to="/cadastros-operacionais/checktrechos/new"
        >
          <v-icon>mdi-plus</v-icon>
          Novo
        </v-btn>
        <v-btn
          color="blue-grey darken-2"
          text
          dark
          class="mb-2 ml-2 mt-2"
          @click="initialize"
        >
          <v-icon class="mr-1">mdi-update</v-icon>
          Recarregar
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        class="elevation-0"
      >
        <template v-slot:item.action="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editar(item.id_viajem)"
            color="primary"
            >mdi-pencil</v-icon
          >
          <v-icon small @click="deleteItem(item.id_viajem)" color="error"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    search: "",
    headers: [
      // { text: "Id Viagem", value: "id_viajem", sortable: false },
      { text: "Viagem", value: "descricao", sortable: false },
      { text: "Ações", align: "right", value: "action", sortable: false },
    ],
    items: [],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.items = [];
      this.$store.dispatch("activeLoader", true);
      this.$http.get("/checartrechos").then((r) => {
        this.items = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    editar(id) {
      this.$router.push(`/cadastros-operacionais/checktrechos/editar/${id}`);
    },
    deleteItem(id) {
      let c = confirm("Deseja apagar o registro?");
      if (c) {
        this.$store.dispatch("activeLoader", true);
        this.$http.post(`/checartrechos/delete/travel/${id}`).then((r) => {
          this.$store.dispatch("activeLoader", false);
          alert(r.data.success);
          this.initialize();
        });
      }
    },
  },
};
</script>

